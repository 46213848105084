/* eslint camelcase: "off" */

import * as AdminActions from './admins';
import * as TicketActions from './tickets';
import * as AgencyActions from './agencies';
import * as AgencyTypesActions from './agency_types';
import * as UserActions from './users';
import * as CameraActions from './cameras';
import * as PaymentsActions from './payments';
import * as ParkingLotActions from './parking_lots';
import * as VehiclesActions from './vehicles';
import * as ParkingSlotActions from './parking_slots';
import * as ParkingLotCameraActions from './parking_lots_camera';
import * as ParkingSessionActions from './parking_sessions';
import * as ReportActions from './reports';
import * as ParkingLotVoiActions from './parking_lot_voi';
import * as ServerErrorActions from './server_errors';
import * as DetailedReports from './detailed_reports';
import * as RoleActions from './roles';
import * as SideNavActions from './side_navigation';
import * as ViolationActions from './violations';
import * as ViolationTypeActions from './violation_types';
import * as CommentActions from './comments';
import * as PermitTypesActions from './permit_types';
import * as ActivityLogActions from './activity_logs';
import * as NotificationActions from './notifications';
import * as SubscriberActions from './subscribers';
import * as DisputeActions from './disputes';
import * as SubscriberVehicleActions from './subscriber_vehicles';
import * as PermitApplicationActions from './permit_applications';
import * as PaymentActivityLogActions from './payment_activity_logs';
import * as PermitActions from './permits';
import * as CarpoolPermitActions from './carpool_pemits';
import * as SpecialPermitTypeActions from './special_permit_types';
import * as SpecialPermitActions from './special_permits';
import * as ReservedParkingLotActions from './reserved_parking_lots';
import * as ParkingCitationTicketAction from './parking_citation_tickets';
import * as MonitorVehiclesActions from './monitor_vehicles';
import * as EventParkingApplicationsActions from './event_parking_applications';
import * as EventsActions from './events' 
import * as RewardTypesActions from './reward_types';
import * as CouponsActions from './coupons';
import * as SubscriberPermitsActions from './subscriber/permits';
import * as SubscriberPermitApplicaitonsActions from './subscriber/permit_applications';
import * as SubscriberNotificationsActions from './subscriber/notifications';
import * as PermitTypeCodesActions from './permit_type_codes';
import * as BootingsActions from './booting';
import * as EmailsActions from './emails';
import * as ParkingRulesActions from './parking_rules';
import * as ViolatorActions from './violators';
import * as PermitWaitingListActions from './permit_waiting_lists';
import * as SubscriberCitationsActions from './subscriber/citations';
import * as SubscriberVehiclesActions from './subscriber/vehicles';
import * as AccountTypeActions from './account_types';
import * as EventCategoriesActions from './event_categories';
import * as CityEventsActions from './city_events';
import * as UserRewardsActions from './user_rewards';
import * as UserCouponsActions from './user_coupons';
import * as UserChargingSessionsActions from './user_charging_sessions';
import * as ThemesActions from './themes';
import * as BroadCastMessagesActions from './broadcast_messages';
import * as ParkingRegulationsActions from './parking_regulations';
import * as ChargingSessionActions from './charging_sessions';
import * as PastChargingSessionActions from './past_charging_sessions';
import * as ManufacturersActions from './manufacturers';
import * as DisabilitiesActions from './disabilities';
import * as ValetParkingsActions from './valet_parkings';
import * as ValueAddedServicesActions from './value_added_services';
import * as UsersEnrolledActions from './users_enrolled'
import * as SystemLogsActions from './system_logs'
import * as TowingsActions from './towings';
import * as BootEligibleVehiclesActions from './boot_eligible_vehicles';
import * as ResidentialTypesActions from './residential_types'
import * as ValetStaffManagementActions from './valet_staff_management'
import * as VehicleCitationTicketActions from './vehicle_citation_tickets'
import * as PermitReportActions from './system_reports/permit_reports'

const INIT_SET_TOKEN = 'APP_INIT_SET_TOKEN';
const INIT_SET_CURRENT_USER = 'APP_INIT_SET_CURRENT_USER';

const init_set_token = {
  type: INIT_SET_TOKEN
};

const init_set_current_user = {
  type: INIT_SET_CURRENT_USER
};

const invoke = (type) => {
  return (payload) => {
    return {
      type,
      payload
    };
  };
};

export {
  VehiclesActions,
  UserActions,
  AdminActions,
  AgencyActions,
  AgencyTypesActions,
  CameraActions,
  ParkingLotActions,
  PaymentsActions,
  ParkingSlotActions,
  ParkingLotCameraActions,
  ParkingSessionActions,
  ReportActions,
  TicketActions,
  DetailedReports,
  RoleActions,
  ViolationActions,
  ViolationTypeActions,
  CommentActions,
  ActivityLogActions,
  INIT_SET_TOKEN,
  INIT_SET_CURRENT_USER,
  init_set_token,
  init_set_current_user,
  ParkingLotVoiActions,
  ServerErrorActions,
  invoke,
  SideNavActions,
  PermitTypesActions,
  NotificationActions,
  SubscriberActions,
  SubscriberVehicleActions,
  DisputeActions,
  PermitApplicationActions,
  PaymentActivityLogActions,
  PermitActions,
  CarpoolPermitActions,
  SpecialPermitTypeActions,
  SpecialPermitActions,
  ReservedParkingLotActions,
  ParkingCitationTicketAction,
  MonitorVehiclesActions,
  EventParkingApplicationsActions,
  EventsActions,
  RewardTypesActions,
  CouponsActions,
  SubscriberPermitsActions,
  SubscriberPermitApplicaitonsActions,
  SubscriberNotificationsActions,
  PermitTypeCodesActions,
  BootingsActions,
  EmailsActions,
  ParkingRulesActions,
  ViolatorActions,
  PermitWaitingListActions,
  SubscriberCitationsActions,
  SubscriberVehiclesActions,
  AccountTypeActions,
  EventCategoriesActions,
  CityEventsActions,
  UserRewardsActions,
  UserCouponsActions,
  UserChargingSessionsActions,
  ThemesActions,
  BroadCastMessagesActions,
  ParkingRegulationsActions,
  ChargingSessionActions,
  PastChargingSessionActions,
  ManufacturersActions,
  DisabilitiesActions,
  ValetParkingsActions,
  ValueAddedServicesActions,
  UsersEnrolledActions,
  SystemLogsActions,
  TowingsActions,
  BootEligibleVehiclesActions,
  ResidentialTypesActions,
  ValetStaffManagementActions,
  VehicleCitationTicketActions,
  PermitReportActions
};
