import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { Form } from 'informed';
import { configureFields } from 'components/helpers/fields/permit_waiting_lists';
import Button from 'components/base/button';
import { isEmpty, map } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { searchV1 as dropdownsSearch } from "api/dropdowns";

const state = {
  selectedCheckboxes: []
};
const { selectedCheckboxes } = state;

const priorityList= [
  { label: 'Amount spend on parking', value: 'amount_spent_on_parking' },
  { label: 'Parking sessions completed', value: 'parking_sessions_completed' },
  { label: 'Account type', value: 'account_type' },
  { label: 'Violations committed', value: 'violations_committed' },
  { label: 'User age', value: 'user_age' }
]

const Configure = (props) => {
  const { closeModal, selectedRecord, isSaving, updateWaitlist, errors, setErrors, setState} = props
  const [prioritySections, setPrioritySections] = useState([]);
  const [priorityElements, setPriorityElements] = useState([]);
  const [updateVS, setUpdateVS] = useState(false)
  const [checkboxSelected, setCheckboxSelected] = useState(true)
  const [accountSelected, setAccountSelected] = useState(selectedRecord?.priority_criteria ? selectedRecord?.priority_criteria?.some(item => item.name === 'account_type') : false)
  const [accountTypes, setAccountTypes] = useState()
  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customInputClass1: styles.input1,
    customInputClass2: styles.input2,
    customLabelClass2: styles.inputLabel2,
    disabled: selectedRecord?.waitlist_type === 'lottery' || selectedRecord?.waitlist_type === 'default',
    accountTypes: accountTypes
  }
  const onChange = (label) => {
    
    const selectedCheckboxes = state.selectedCheckboxes;
    const findIdx = selectedCheckboxes.indexOf(label);
    if(label === 'account_type' && accountSelected){
      setAccountSelected(false)
    }else if(label === 'account_type' && !accountSelected){
      setAccountSelected(true)
    }
    if(label!=='account_type'){
      if (findIdx > -1) {
      selectedCheckboxes.splice(findIdx, 1);
      setPrioritySections((prevSections) => prevSections.filter((s) => s !== label));
      setUpdateVS(true);
      } else {
        selectedCheckboxes.push(label);
        setUpdateVS(true);
        if(!prioritySections.includes(label)){
          setPrioritySections((prevSections) => [...prevSections, label]);
        }
      }
    }
    setCheckboxSelected(true)
    setState({
      selectedCheckboxes: selectedCheckboxes
    });
  };

  useEffect(() => {
    const fetchAccountTypes = async () => {
      try {
        const accountTypeList = await dropdownsSearch("account_types_list");
        setAccountTypes(accountTypeList.data);
      } catch (_error) {}
    };
    if (isEmpty(accountTypes)) fetchAccountTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = useCallback(() => {
    const values = {...selectedRecord}
    let arr ={}
    // eslint-disable-next-line
    {map(selectedRecord?.priority_criteria, (key, idx) => {
      if(key.name === 'account_type') Object.assign(arr, { [`${key.name}`]: {account_type_id: key?.account_type_id}} )
      if(key.name !== 'account_type') Object.assign(arr, { [`${idx+1}-${key.name}`]: {min: key?.min, max: key?.max}} )
    })}
    const updatedHash = {
      ...values,
      priority: arr
    };
    if(selectedRecord?.priority_criteria?.some(item => item.name === 'account_type')){
      Object.assign(updatedHash, {priority_criteria: { account_type: arr?.account_type?.account_type_id}})
    }
    return updatedHash
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const accountFieldProps = {
    iSize: 7,
    lSize: 5,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const typeFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => {
        setErrors({});
        setState('selectedCheckboxes', []);
        setPrioritySections([])
      }
    }
  }

  const minFieldProps = {
    iSize: 5,
    lSize: 7,
    events: {
      onChange: (_e) => {
        setErrors({});
      }
    }
  }

  const maxFieldProps = {
    iSize: 13,
    lSize: 3,
    events: {
      onChange: (_e) => {
        setErrors({});
      }
    }
  }

  useEffect(() => {
    setState('selectedCheckboxes', []);
    setPrioritySections([])
    const selectedCheckboxes = state.selectedCheckboxes;
    // eslint-disable-next-line
    {map(selectedRecord?.priority_criteria, (item) => (
        <p>{!selectedCheckboxes.includes(item?.name) && selectedCheckboxes.push(item?.name)}</p>
    ))}
    setPrioritySections(selectedCheckboxes)
    setState({selectedCheckboxes: []});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let arr =[]
    // eslint-disable-next-line
    {map(selectedCheckboxes, (item, idx) => (
      arr.push(`${idx+1}-${item}`)
    ))}
    setPriorityElements(arr)
    setUpdateVS(false);
    // eslint-disable-next-line
  }, [ updateVS, state]);

  const validatePriorityAttrs = (vehicleAttrs={}) => {
    let errors = {}
      Object.keys(vehicleAttrs).forEach(function (key) {
        if(key !== 'account_type' && !vehicleAttrs?.[key]?.min) { errors[`priority[${key}][min]`] = ['Min value is required']}
        if(key !== 'account_type' && !vehicleAttrs?.[key]?.max) { errors[`priority[${key}][max]`] = ['Max value is required']}
        if(key !== 'account_type' && vehicleAttrs?.[key]?.max && vehicleAttrs?.[key]?.min && vehicleAttrs?.[key]?.max <= vehicleAttrs?.[key]?.min) { 
          errors[`priority[${key}][max]`] = ['Max value should be greater than min value']
        }
      })
      // eslint-disable-next-line
      {map(priorityElements, (item) => (
        <p>{item.split('-')[1] !== 'account_type' && !vehicleAttrs?.hasOwnProperty(item) 
          && (errors[`priority[${item}][min]`] = ['Min value is required']) 
          && (errors[`priority[${item}][max]`] = ['Max value is required'])}
        </p>
      ))}
    return errors
  }

  const validateValues = (formState) => {
    const errors = {}
    const { waitlist_type, max_users, status } = formState;
    if(!waitlist_type) { errors['waitlist_type'] = ['Waitlist Type is required']}
    if(waitlist_type === 'lottery' && !max_users) { errors['max_users'] = ['Max Users is required']}
    if(!status) { errors['status'] = ['Status is required']}
    return Object.assign(
      errors,
      validatePriorityAttrs(formState?.priority),
    )
  }

  const submitValues = (values) => {
    setErrors({})
    let arr =[]
    if(values?.priority_criteria?.account_type){
      arr.push({name: 'account_type', account_type_id: values?.priority_criteria?.account_type})
    }
    // eslint-disable-next-line
    {values?.priority && Object.keys(values?.priority).forEach(function (key) {
      arr.push({name: key.split('-')[1], min: values?.priority?.[key]?.min, max: values?.priority?.[key]?.max})
    })}

    const inputErrors = validateValues(values, arr)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    if(isEmpty(values?.priority)){
      setCheckboxSelected(false)
    }

    const updatedHash = {
      ...values,
      priority_criteria: arr,
      id: selectedRecord?.id
    };
    
    setState('selectedCheckboxes', []);
    updateWaitlist(updatedHash)
  }

  return (
    <Container className='p-0'>
      <Form initialValues={initialValues()} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const {waitlist_type} = formState.values
          return (
            <Col>
              <Col>
                <div className={`${waitlist_type !== 'priority' ? 'd-none' : 'd-flex align-items-center my-3'}`}>
                  <span className={styles.detailsLabel}>Waitlist Details</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                <div style={{ margin: `${waitlist_type === 'priority' ? '10px' : ''}`}}>
                  {renderFields(configureFields({...fieldAttrs}).slice(0, 1), {...singleFieldProps, errors})}
                  {renderFields(configureFields({...fieldAttrs}).slice(1, 2), {...typeFieldProps, errors})}
                  {waitlist_type === 'lottery' && renderFields(configureFields({...fieldAttrs}).slice(2, 3), {...singleFieldProps, errors})}
                </div>
              </Col>
              {waitlist_type === 'priority' && <Col>
                <div className="d-flex align-items-center my-3">
                  <span className={styles.detailsLabel}>Setting Eligible Criteria</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                <div style={{ margin: '10px'}}>
                {map(priorityList, (item, idx) => ( 
                  <Row className={styles.checkOuter}>
                    <input
                      type="checkbox"
                      defaultChecked={selectedCheckboxes.includes(item?.value)}
                      value={item?.value}
                      onChange={() => onChange(item?.value, idx+1)}
                      className={styles.customCheckStyle}
                    />
                    <label className={styles.checkboxText}>{item?.label}</label>
                  </Row>
                ))}
                {!checkboxSelected && !accountSelected && <p className='text-left general-error general-text-1 pt-1'>Select atleast one criteria</p>}
                </div>
              </Col>}
              {waitlist_type === 'priority' && !isEmpty(prioritySections) && <Col>
                <div className="d-flex align-items-center my-3">
                  <span className={styles.detailsLabel}>Spendings on Parking Details</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                {map(prioritySections, (item, idx) => ( 
                  <Row key={idx} style={{ margin: '10px'}} className={item === 'account_type' ? 'd-none' : 'd-flex'}>
                    <Col xs={8}>
                      {renderFields(configureFields({name: item, id: idx+1, ...fieldAttrs}).slice(3, 4), {...minFieldProps, errors})}
                    </Col>
                    <Col xs={4}>
                      {renderFields(configureFields({name: item, id: idx+1,  ...fieldAttrs}).slice(4, 5), {...maxFieldProps, errors})}
                    </Col>
                  </Row>
                ))}
                {accountSelected && 
                  <Col >
                    <div style={{ margin: '10px'}}>
                    {renderFields(configureFields({...fieldAttrs}).slice(5, 6), {...accountFieldProps, errors})}
                    </div>
                  </Col>
                }
              </Col>}
              <Col className='d-flex justify-content-center'>
                <Button 
                  onClick={()=>  closeModal()}
                  className={`${styles.cancelButton} mb-3`}
                >
                  Cancel
                </Button>
                <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                  Save
                </Button>
              </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (Configure);