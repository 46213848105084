import React, { useState, useEffect, useCallback } from "react";
import {
  ConfigProvider,
  Form,
  Input,
  Button,
  Steps,
  Upload,
  message,
  Modal,
  Table,
  Row,
  Col,
  Select,
  Tag,
  Alert,
  Space,
  Progress,
  Radio,
  DatePicker,
  Divider,
} from "antd";
import {
  PlusOutlined,
  CloudUploadOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import styles from "./index.module.sass";
import PhoneInput from "react-phone-input-2";
import { searchV1 as dropdownsSearch } from "api/dropdowns";
import { setSubscriberToken, setCurrentSubData } from "actions/users";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
/* Modules */
import { isEmpty } from "underscore";
import { ReactComponent as EditIcon } from "assets/edit_icon.svg";
import { ReactComponent as TrashIcon } from "assets/trash_icon.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signUp } from "api/webapp/subscriber";
import withFetching from "components/modules/with_fetching";
/* Modules */
import RedirectIfAuthorized from "components/modules/redirect_if_authorized";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import moment from "moment";
import { validateStep2 } from "api/app/apply_permit";
import { Spinner, Container } from "reactstrap";
import AddVehicle from "./add_vehicle";
import { debounce } from "lodash";
import WebAppHeader from "components/base/webapp_header";
import { useTranslation } from "react-i18next";
import 'components/pages/signup/antd.css'

const { Dragger } = Upload;

const { Step } = Steps;

const SignUpPage = (props) => {
  const { history } = props;
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [profilePicture, setProfilePicture] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [countries, setCountries] = useState();
  const [accountTypes, setAccountTypes] = useState([]);
  const [stateList, setStateList] = useState();
  const [manufacturers, setManufacturers] = useState();
  const [disabilities, setDisabilities] = useState();
  const [idProofFileName, setIdProofFileName] = useState("");
  const [base64IdProof, setBase64IdProof] = useState(null);
  const [noteVisible, setNoteVisible] = useState(true);
  const [base64ProfilePicture, setBase64ProfilePicture] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isNewVehicle, setIsNewVehicle] = useState();
  const [requireAccessibilityAsst, setRequireAccessibilityAsst] =
    useState(false);
  const [countryCode, setCountryCode] = useState("us");
  const [dlnExpiryDate, setDlnExpiryDate] = useState("");
  // eslint-disable-next-line
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [disabitlityCertFileName, setDisabilityCertFileName] = useState("");
  const [base64DisabilityCert, setBase64DisabilityCert] = useState(null);
  const [emailExists, setEmailExists] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState();
  const handleNoteClose = () => {
    setNoteVisible(false);
  };

  const btnSpinner = (props = {}) => {
    return (
      <span>
        <Spinner {...props} size="sm" color="default" />
      </span>
    );
  };
  const nextStep = async () => {
    try {
      await form.validateFields();
      if (currentStep === 3) {
        submitForm(form.getFieldsValue());
      } else {
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const prevStep = () => setCurrentStep(currentStep - 1);

  const uploadProps = {
    name: "file",
    accept: "image/*",
    showUploadList: false,
    beforeUpload: (file) => {
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isValidFileType) {
        message.error(
          t("signup.validations.invalid_file_format")
        );
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setProfilePicture(file);
        setBase64ProfilePicture(reader.result);
      };
      return false;
    },
    onChange: (info) => {
      if (info.file.status === "uploading") {
        setUploadProgress(info.file.percent);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} ${t("signup.validations.file_upload_success")}`);
        setUploadProgress(0);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} ${t("signup.validations.file_upload_fail")}`);
        setUploadProgress(0);
      }
    },
  };

  const uploadIDProps = {
    name: "file",
    accept: ".jpg,.jpeg,.png,.pdf",
    showUploadList: false,
    beforeUpload: (file) => {
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "application/pdf";
      if (!isValidFileType) {
        message.error(
          "Invalid file format. Please upload JPG, JPEG, PNG, or PDF files only."
        );
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setIdProofFileName(file?.name);
      reader.onload = () => {
        setBase64IdProof(reader.result);
      };
      return false;
    },
    onChange: (info) => {
      if (info.file.status === "uploading") {
        setUploadProgress(info.file.percent);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setUploadProgress(0);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setUploadProgress(0);
      }
    },
    customRequest: ({ file, onError, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  const validateIDProof = async () => {
    if (!idProofFileName && currentStep === 1) {
      return Promise.reject(t("signup.validations.id_proof"));
    }
  };


  const uploadCertificateProps = {
    name: "file",
    accept: "image/*,.pdf",
    showUploadList: false,
    beforeUpload: (file) => {
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "application/pdf";
      if (!isValidFileType) {
        message.error(
          "Invalid file format. Please upload JPG, JPEG, PNG, or PDF files only."
        );
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setDisabilityCertFileName(file?.name);
      reader.onload = () => {
        setBase64DisabilityCert(reader.result);
      };
      return false;
    },
    onChange: (info) => {
      if (info.file.status === "uploading") {
        setUploadProgress(info.file.percent);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setUploadProgress(0);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setUploadProgress(0);
      }
    },
    customRequest: ({ file, onError, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  const removeProfilePicture = () => {
    setProfilePicture(null);
    setBase64ProfilePicture("");
  };

  const removeIDProof = () => {
    setBase64IdProof(null);
    setIdProofFileName("");
  };

  const removeDisabilityCert = () => {
    setBase64DisabilityCert(null);
    setDisabilityCertFileName("");
  };

  const showModal = () => {
    setIsNewVehicle(true);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedVehicle(null);
  };

  const handleModalSave = (values) => {
    const existingVehicle = vehicles.find(vehicle => vehicle.plateNumber === values.plateNumber);
    const vehicleToBeUpdated = existingVehicle? existingVehicle : selectedVehicle
    if (vehicleToBeUpdated) {
      const updatedVehicles = vehicles.map((vehicle) =>
        vehicle === vehicleToBeUpdated ? { ...vehicle, ...values } : vehicle
      );
      setVehicles(updatedVehicles);
    } else {
      setVehicles([...vehicles, values]);
    }
    setModalVisible(false);
    setSelectedVehicle(null); 
  };


  const handleRemove = (record) => {
    setVehicles(vehicles.filter((vehicle) => vehicle !== record));
    if (selectedVehicle === record) {
      setSelectedVehicle();
    }
  };

  const registerUser = useCallback(async (user) => {
    setIsSaving(true);
    try {
      const response = await signUp({ user });
      if (response.status === 200 || response.status === 201) {
        window.sessionStorage.setItem("userEmail", user?.email);
        window.sessionStorage.setItem("userName", user?.first_name);
        localStorage.removeItem('countdownEndTime');
        history.push("/confirm_user_account");
      }
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    } finally {
      setIsSaving(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = useCallback(
    (values) => {
      const formattedVehiclesData = vehicles?.map((v, idx) => {
        return {
          plate_number: v?.plateNumber,
          vehicle_type: v?.vehicle_type,
          color: v?.color,
          model: v?.model,
          manufacturer_id: v?.make,
          registration_card: base64IdProof,
          registration_state: v?.state,
          category: v?.category,
          vehicle_class: v?.vehicle_class,
          sub_class: v?.vehicle_sub_class,
        };
      });
      const data = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        avatar: base64ProfilePicture,
        account_type_id: values?.accountType,
        phone: values?.phoneNumber,
        email: values.email,
        address_proof: base64IdProof,
        password: values?.confirm_password,
        vehicles: formattedVehiclesData,
        id_number: values?.idNumber,
        dln_expiry_date: dlnExpiryDate,
        accessibility_assistance: `${requireAccessibilityAsst}`,
        disability_certificate: requireAccessibilityAsst
          ? base64DisabilityCert
          : null,
        disabilities_ids: values?.disabilities_ids,
        home_address: {
          address1: values?.address1,
          address2: values?.address2,
          city: values?.city,
          state_code: values?.state,
          country_code: values?.country,
          postal_code: values?.zipcode,
        },
      };
      registerUser(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      vehicles,
      requireAccessibilityAsst,
      base64ProfilePicture,
      base64IdProof,
      dlnExpiryDate,
      requireAccessibilityAsst,
      base64DisabilityCert,
    ]
  );

  const handleEdit = (record) => {
    setIsNewVehicle(false);
    setSelectedVehicle(record);
    setModalVisible(true);
  };

  const columns = [
    {
      title: t("vehicle_attributes.plate_number.label"),
      dataIndex: "plateNumber",
      key: "plateNumber",
    },
    {
      title: t("vehicle_attributes.model.label"),
      dataIndex: "model",
      key: "model",
    },
    {
      title: t("vehicle_attributes.state.label"),
      dataIndex: "state",
      key: "state",
    },
    {
      title: t("vehicle_attributes.color.label"),
      dataIndex: "color",
      key: "color",
    },
    {
      title: t("buttons.actions"),
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            type="text"
            icon={<EditIcon className="svg-dark position-relative" />}
            onClick={() => handleEdit(record)}
          />
          <Button
            type="text"
            icon={<TrashIcon />}
            onClick={() => handleRemove(record)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesList = await dropdownsSearch("countries_list");
        setCountries(countriesList.data);
      } catch (_error) {}
    };
    if (isEmpty(countries)) fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchAccountTypes = async () => {
      try {
        const accountTypeList = await dropdownsSearch("account_types_list");
        setAccountTypes(accountTypeList.data);
      } catch (_error) {}
    };
    if (isEmpty(accountTypes)) fetchAccountTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const manufacturerList = await dropdownsSearch("manufacturers_list");
        setManufacturers(manufacturerList.data);
      } catch (_error) {}
    };
    if (isEmpty(manufacturers)) fetchManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchDisabilities = async () => {
      try {
        const disabilitiesList = await dropdownsSearch("disabilities_list");
        setDisabilities(disabilitiesList.data);
      } catch (_error) {}
    };
    if (isEmpty(disabilities)) fetchDisabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const statesList = await dropdownsSearch("states_list", {
          country_code: countryCode,
        });
        setStateList(statesList.data);
      } catch (_error) {}
    };
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const disabledExpiryDates = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf("day");
  };

  const validateEmail = async (email) => {
    setEmailExists(false);
    try {
      // eslint-disable-next-line
      const response = await validateStep2({ email: email });
      setEmailExists(false);
    } catch (_error) {
      if (_error?.response?.data?.errors?.email) {
        setEmailExists(true);
      }
    }
  };

  const debouncedHandleFieldChange = debounce((_, allFields) => {
    const updatedErrors = { ...errors };
    allFields.forEach((field) => {
      updatedErrors[field.name[0]] = field.errors.length > 0;
    });
    setErrors(updatedErrors);
  }, 300);

  const handleFieldChange = (_, allFields) => {
    debouncedHandleFieldChange(_, allFields);
  };

  const getLabel = (fieldName, label, required) => {
    const isError = errors?.[fieldName] || false;
    return (
      <Col className={styles.labelCol}>
        <span className={styles.formLabel}>{t(`signup.${label}`)}</span>
        {required && (
          <Tag
            className={styles.requiredMark}
            style={{ color: isError ? "rgba(251, 116, 91, 0.6)" : "#3a9ced" }}
          >
            *
          </Tag>
        )}
      </Col>
    );
  };

  return (
    <Container className='m-0 p-0 mw-100'> 
      <WebAppHeader/>
    <div className={styles.signUpPage}>
      <div className={styles.formContainer}>
        <h2 className={styles.registerText}>{t("register")}</h2>
        <ConfigProvider
          theme={{
            components: {
              Form: {
                labelColor: "#242E42",
                labelRequiredMarkColor: "#7ab7f3",
                labelFontSize: "13px",
                colorError: "rgba(251, 116, 91, 0.9)",
                fontFamily: "Roboto, sans-serif",
                fontSize: "13px",
              },
              Radio: {
                buttonBg: "#000000",
              },
            },
            token: {
              colorPrimary: "#1890ff",
              fontFamily: "Roboto, sans-serif",
            },
          }}
        >
          <Steps
            current={currentStep}
            className={styles.steps}
            responsive={true}
          >
            <Step title={t("signup.Personal")} />
            <Step title={t("signup.Address")} />
            <Step title={t("signup.Vehicles")} />
            <Step title={t("signup.Submit")} />
          </Steps>
          <Form
            name="signup"
            form={form}
            layout="vertical"
            colon={false}
            onFieldsChange={handleFieldChange}
            requiredMark={false}
            className={styles.form}
            labelAlign="left"
            onFinish={(values) => submitForm(values)}
          >
            <Col className={currentStep === 0 ? "" : "d-none"}>
              <Row className="d-flex justify-content-center align-items-center pb-4">
                <Upload {...uploadProps}>
                  {profilePicture ? (
                    <div className={styles.uploadedFileContainer}>
                      <img
                        src={base64ProfilePicture}
                        alt="Profile"
                        className={styles.profilePicture}
                      />
                      <Button
                        type="text"
                        className={styles.deleteButton}
                        onClick={removeProfilePicture}
                        icon={<TrashIcon className={styles.removeIcon} />}
                      />                        
                    </div>
                  ) : (
                    <div
                      className={styles.upload}
                      style={{ opacity: profilePicture ? 0.5 : 1 }}
                    >
                      <PlusOutlined />
                      <div className={styles.uploadText}>{t("signup.Upload")}</div>
                    </div>
                  )}
                </Upload>
              </Row>
              <div className={styles.formRow}>
                <Form.Item
                  name="firstName"
                  label={getLabel("firstName", "First Name", currentStep === 0)}
                  rules={[
                    {
                      required: true,
                      message: t("signup.validations.first_name"),
                    },
                  ]}
                  className={styles.formItem}
                >
                  <Input className={styles.formInput} />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label={getLabel("lastName", "Last Name", currentStep === 0)}
                  rules={[
                    {
                      required: currentStep === 0,
                      message: t("signup.validations.last_name"),
                    },
                  ]}
                  className={styles.formItem}
                >
                  <Input name="lastName" className={styles.formInput} />
                </Form.Item>
              </div>
              <div className={styles.formRow}>
                <Form.Item
                  name="phoneNumber"
                  label={getLabel(
                    "phoneNumber",
                    "Phone Number",
                    currentStep === 0
                  )}
                  rules={[
                    {
                      required: currentStep === 0,
                      message: t("signup.validations.phone_number.required"),
                    },
                    {
                      pattern: /^\d{11 || 12}$/,
                      message: t("signup.validations.phone_number.format"),
                    },
                  ]}
                  className={styles.formItem}
                >
                  <PhoneInput
                    inputClass={styles.phoneInput}
                    dropdownClass={styles.dropdown}
                    searchClass={styles.search}
                    searchStyle={{
                      fontSize: "13px",
                      height: "30px",
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    disableSearchIcon
                    searchPlaceholder="Search for Countries"
                    searchNotFound="Not Found"
                    country="us"
                    enableSearch={true}
                    autocompleteSearch={true}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={getLabel("email", "Email", currentStep === 0)}
                  rules={[
                    {
                      required: currentStep === 0,
                      message: t("signup.validations.email.required"),
                    },
                    {
                      type: "email",
                      message: t("signup.validations.email.format"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || !emailExists) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t('signup.validations.email.unique'))
                        );
                      },
                    }),
                  ]}
                  className={styles.formItem}
                >
                  <Input
                    className={styles.formInput}
                    onChange={(e) => validateEmail(e.target.value)}
                  />
                </Form.Item>
              </div>
              <div className={styles.formRow}>
                <Form.Item
                  name="dateOfBirth"
                  label={getLabel(
                    "dateOfBirth",
                    "Date of Birth",
                    currentStep === 0
                  )}
                  rules={[
                    {
                      required: currentStep === 0,
                      message: t("signup.validations.dob.required"),
                    },
                  ]}
                  className={styles.formItem}
                >
                  <DatePicker
                    disabledDate={(current) =>
                      current && current > moment().subtract(18, "years")
                    }
                    suffixIcon={
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        color="grey"
                        className={styles.calenderIcon}
                      />
                    }
                    placeholder={t("signup.validations.dob.placeholder")}
                    className={styles.calenderInput}
                    format="DD/MM/YYYY"
                    onChange={(date, dateString) => setDateOfBirth(dateString)}
                  />
                </Form.Item>
                <Form.Item
                  name="accountType"
                  label={getLabel(
                    "accountType",
                    "Account Type",
                    currentStep === 0
                  )}
                  rules={[
                    {
                      required: currentStep === 0,
                      message: t("signup.validations.account_type.required"),
                    },
                  ]}
                  className={styles.formItem}
                >
                  <Select
                    options={accountTypes ? accountTypes : undefined}
                    placeholder={t("signup.validations.account_type.placeholder")}
                    showSearch
                    optionFilterProp="label"
                    className={styles.formInput}
                  />
                </Form.Item>
              </div>
              <div className={styles.formRow}>
                <Form.Item
                  name="idNumber"
                  label={getLabel(
                    "idNumber",
                    "Driving License Number",
                    currentStep === 0
                  )}
                  rules={[
                    {
                      required: currentStep === 0,
                      message: t("signup.validations.dln"),
                    },
                  ]}
                  className={styles.formItem}
                >
                  <Input className={styles.formInput} />
                </Form.Item>
                <Form.Item
                  name="expiryDate"
                  label={getLabel(
                    "expiryDate",
                    "Expiration Date",
                    currentStep === 0
                  )}
                  rules={[
                    {
                      required: currentStep === 0,
                      message: t("signup.validations.dln_expiry.required"),
                    },
                  ]}
                  className={styles.formItem}
                >
                  <DatePicker
                    disabledDate={disabledExpiryDates}
                    suffixIcon={
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        color="grey"
                        className={styles.calenderIcon}
                      />
                    }
                    className={styles.calenderInput}
                    format="DD/MM/YYYY"
                    placeholder={t("signup.validations.dln_expiry.placeholder")}
                    onChange={(date, dateString) =>
                      setDlnExpiryDate(dateString)
                    }
                  />
                </Form.Item>
              </div>
            </Col>

            <Col className={currentStep === 1 ? "" : "d-none"}>
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                className={styles.instructions}
              >
                {noteVisible && (
                  <Alert
                    message={t("signup.note")}
                    type="info"
                    closable
                    afterClose={handleNoteClose}
                  />
                )}
              </Space>
              <Form.Item
                name="address1"
                label={getLabel(
                  "address1",
                  "Address Line 1",
                  currentStep === 1
                )}
                rules={[
                  {
                    required: currentStep === 1,
                    message: t("signup.validations.address1"),
                  },
                ]}
              >
                <Input className={styles.formInput} />
              </Form.Item>
              <Form.Item
                name="address2"
                label={getLabel(
                  "address2",
                  "Address Line 2",
                  false
                )}
              >
                <Input className={styles.formInput} />
              </Form.Item>
              <div className={styles.formRow}>
                <Form.Item
                  name="country"
                  label={getLabel("country", "Country", currentStep === 1)}
                  rules={[
                    {
                      required: currentStep === 1,
                      message: t("signup.validations.country"),
                    },
                  ]}
                  className={styles.formItem}
                >
                  <Select
                    options={countries}
                    showSearch
                    optionFilterProp="label"
                    className={styles.formInput}
                    onChange={(e) => setCountryCode(e)}
                  />
                </Form.Item>
                <Form.Item
                  name="state"
                  label={getLabel("state", "State", currentStep === 1)}
                  rules={[
                    {
                      required: currentStep === 1,
                      message: t("signup.validations.state"),
                    },
                  ]}
                  className={styles.formItem}
                >
                  <Select
                    options={stateList}
                    showSearch
                    optionFilterProp="label"
                    className={styles.formInput}
                  />
                </Form.Item>
              </div>
              <div className={styles.formRow}>
                <Form.Item
                  name="city"
                  label={getLabel("city", "City", currentStep === 1)}
                  className={styles.formItem}
                  rules={[
                    {
                      required: currentStep === 1,
                      message: t("signup.validations.city"),
                    },
                  ]}
                >
                  <Input className={styles.formInput} />
                </Form.Item>
                <Form.Item
                  name="zipcode"
                  label={getLabel("zipcode", "Postal Code", currentStep === 1)}
                  className={styles.formItem}
                  rules={[
                    {
                      required: currentStep === 1,
                      message: t("signup.validations.postal_code"),
                    },
                  ]}
                >
                  <Input className={styles.formInput} />
                </Form.Item>
              </div>
              <Form.Item
                name="id_proof"
                label={getLabel(
                  "id_proof",
                  "idProof",
                  !idProofFileName && currentStep === 1
                )}
                valuePropName="fileList"
                getValueFromEvent={(e) => e && [e.file]}
                rules={[
                  {
                    validator: validateIDProof,
                  },
                ]}
              >
                <Dragger {...uploadIDProps}>
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined
                      style={{ color: idProofFileName ? "#3A9CED" : "grey" }}
                    />
                  </p>
                  <p className="ant-upload-text">
                    {!idProofFileName
                      ? t("signup.uploadText")
                      : idProofFileName}
                  </p>
                  <p className="ant-upload-hint">
                    {t("signup.formatsText")}
                  </p>
                  {idProofFileName && (
                    <Button
                      type="text"
                      className={styles.deleteIdProofButton}
                      onClick={removeIDProof}
                      icon={<TrashIcon className={styles.removeIcon} />}
                    />
                    
                  )}
                </Dragger>
              </Form.Item>
            </Col>
            <Col
              className={
                currentStep === 2 ? "m-0 p-0 justify-content-center" : "d-none"
              }
            >
              {isEmpty(vehicles) && (
                <p className="text-center">
                  {t("signup.vehiclesText")}
                </p>
              )}
              {!isEmpty(vehicles) && <Divider>{t("signup.addedVehicles")}</Divider>}
              {vehicles.length > 0 && (
                <Table
                  dataSource={vehicles}
                  columns={columns}
                  pagination={false}
                  className={styles.table}
                  sticky={true}
                  scroll={{
                    y: 550,
                  }}
                />
              )}

              <Button
                type="primary"
                icon={<PlusOutlined />}
                className={styles.addButton}
                onClick={showModal}
              >
                {t("signup.Add Vehicle")}
              </Button>
              <Modal
                centered
                title={t("signup.Add Vehicle")}
                open={modalVisible}
                onCancel={handleModalCancel}
                footer={null}
                classNames={{ header: "text-center" }}
              >
                <AddVehicle
                  initialValues={selectedVehicle}
                  onSave={handleModalSave}
                  closeModal={handleModalCancel}
                  manufacturers={manufacturers}
                  isNewVehicle={isNewVehicle}
                  t={t}
                />
              </Modal>
            </Col>
            <Col className={currentStep === 3 ? "" : "d-none"}>
              <div className={styles.formRow}>
                <p>{t("signup.assistancetext")}</p>
                <Radio.Group
                  required
                  className={styles.radioBtns}
                  onChange={(e) => setRequireAccessibilityAsst(e.target.value)}
                  value={requireAccessibilityAsst}
                >
                  <Radio className="pr-4" value={true}>
                    {t("choices.yes")}
                  </Radio>
                  <Radio className="pl-2" value={false}>
                    {t("choices.no")}
                  </Radio>
                </Radio.Group>
              </div>
              {requireAccessibilityAsst && (
                <React.Fragment>
                  <p className="pt-3">
                    {t("signup.disabilityNote")}
                  </p>
                  <div className={`${styles.formRow} pt-2`}>
                    <Form.Item
                      name="disabilities_ids"
                      label={getLabel(
                        "disabilities_ids",
                        "Disabilities",
                        false
                      )}
                      rules={[
                        {
                          required: false ,
                          message: "Please Select Disabitlity",
                        },
                      ]}
                      className={styles.formItem}
                    >
                      <Select
                        mode="multiple"
                        options={disabilities}
                        maxTagCount={"responsive"}
                        showSearch
                        optionFilterProp="label"
                        className={styles.formInput}
                      />
                    </Form.Item>
                    <Form.Item
                      name="additional_note"
                      label={t("signup.Additional Note")}
                      rules={[{ required: false }]}
                      className={styles.formItem}
                    >
                      <Input className={styles.formInput} />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="disability_certificate"
                    label={t("signup.disabilityText")}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e && [e.file]}
                    rules={[{required: false }]}
                  >
                    <Dragger {...uploadCertificateProps}>
                      <p className="ant-upload-drag-icon">
                        <CloudUploadOutlined
                          style={{
                            color: disabitlityCertFileName ? "#3A9CED" : "grey",
                          }}
                        />
                      </p>
                      <p className="ant-upload-text">
                        {!disabitlityCertFileName
                          ? t("signup.uploadText")
                          : `${disabitlityCertFileName}`}
                      </p>
                      <p className="ant-upload-hint">
                        {t("signup.formatsText")}
                      </p>
                      {disabitlityCertFileName && (
                        <Button
                          type="text"
                          className={styles.deleteIdProofButton}
                          onClick={removeDisabilityCert}
                          icon={<TrashIcon className={styles.removeIcon} />}
                        />
                         
                        
                      )}
                    </Dragger>
                  </Form.Item>
                </React.Fragment>
              )}
              <div className={`${styles.formRow} pt-3`}>
                <Form.Item
                  name="password"
                  label={getLabel("password", "Password", currentStep === 3)}
                  placeholder="Password"
                  rules={[
                    {
                      required: currentStep === 3,
                      message: t("signup.validations.password.required"),
                    },
                  ]}
                  className={styles.formItem}
                >
                  <Input.Password
                    placeholder={t("signup.validations.password.placeholder")}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    className={styles.formInput}
                  />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  label={getLabel(
                    "confirm_password",
                    "Confirm Password",
                    currentStep === 3
                  )}
                  placeholder="Confirm Password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: currentStep === 3,
                      message: t("signup.validations.confirm_password.required"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t("signup.validations.confirm_password.does_not_match"))
                        );
                      },
                    }),
                  ]}
                  className={styles.formItem}
                >
                  <Input.Password
                    placeholder={t("signup.validations.confirm_password.placeholder")}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    className={styles.formInput}
                  />
                </Form.Item>
              </div>
            </Col>

            <Form.Item className={styles.buttonContainer}>
              {currentStep > 0 && (
                <Button onClick={prevStep} className={styles.prevButton}>
                  {t("signup.Previous")}
                </Button>
              )}
              {currentStep < 3 && (
                <Button className={styles.button} onClick={nextStep}>
                  {t("signup.Next")}
                </Button>
              )}
              {currentStep === 3 && (
                <Button
                  className={styles.button}
                  type="primary"
                  onClick={nextStep}
                >
                  {isSaving
                    ? btnSpinner({ className: "spinner-border" })
                    : t("signup.Submit")}
                </Button>
              )}
            </Form.Item>
          </Form>
        </ConfigProvider>
        {uploadProgress > 0 && (
          <Progress
            percent={uploadProgress}
            status="active"
            style={{ width: "50%", margin: "20px auto", display: "block" }}
          />
        )}
      </div>
      <ToastContainer position="top-center" />
    </div>
    </Container>
  );
};

function mapDispatch(dispatch) {
  return {
    ...bindActionCreators({ setSubscriberToken, setCurrentSubData }, dispatch),
  };
}

export default connect(
  null,
  mapDispatch
)(withFetching(RedirectIfAuthorized(SignUpPage)));
